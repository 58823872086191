<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-container fluid id="dialog-content">
      <v-col>
        <!-- タイトル -->
        <div id="dialog-title">
          {{ title }}
        </div>
      </v-col>
    </v-container>
    <v-container fluid>
      <p></p>
      <v-row>
        <div class="search-row-exeBtn first-search-item">
          <!-- メッセージ -->
          <div class="" style="float: left" v-html="message"></div>
          <!-- 空白 -->
          <span class="item-spacer">&nbsp;</span>
          <!-- 画面クリアボタン -->
          <div class="btn-area">
            <v-btn class="other-btn" @click="close()">{{ $t("btn.btn_close") }}</v-btn>
          </div>
        </div>
      </v-row>
    </v-container>
    <!-- データテーブル -->
    <v-container fluid>
      <v-data-table
        id="listData"
        :headers="headerItems"
        :items="dataItems"
        :fixed-header="true"
        disable-pagination
        :hide-default-footer="true"
        height="500px"
      >
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import { i18n } from "../lang/lang.js";
import { appConfig } from "../assets/scripts/js/AppConfig";
import { commonUtil } from "../assets/scripts/js/CommonUtil";
import Loading from "./loading";

export default {
  name: "ErrorDetailDialog",
  components: {
    Loading,
  },
  props: {
    isShow: {
      type: Boolean,
      required: true,
    },
    screenId: {
      type: String,
      required: true,
    },
    parentData: {
      type: Array,
      required: true,
    },
    errorData: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    loadingCounter: 0,
    title: "",
    message: "",
    headerItems: [],
    dataItems: [],
  }),
  methods: {
    // 初期化
    init() {
      // リスト初期化
      this.dataItems = [];
      // 出荷実績登録 → 出荷期限切れ
      if (this.screenId == appConfig.SCREEN_ID.P_SHP_006) {
        this.title = i18n.tc("label.lbl_expired_shipment_items");
        this.message = i18n.tc("check.chk_expired_shipment_items");
        this.headerItems = [
          // 品番
          {
            text: i18n.tc("label.lbl_productCode"),
            value: "itemCd",
            width: "50%",
            align: "left",
            sortable: false,
          },
          // 期限日
          {
            text: i18n.tc("label.lbl_dueDate"),
            value: "dueDate",
            width: "30%",
            align: "left",
            sortable: false,
          },
          // 数量
          {
            text: i18n.tc("label.lbl_quantity"),
            value: "quantity",
            width: "20%",
            align: "center",
            sortable: false,
          },
        ];
        // 出荷実績登録のデータ
        for (var i = 0; i < this.parentData.length; i++) {
          // チェックあり
          if (this.parentData[i].check) {
            // エラーデータにチェックデータの存在チェック
            const findErrorData = this.errorData.find((data) => {
              return (
                data.outListSubSid == this.parentData[i].outListSubSid &&
                data.itemStandardSid == this.parentData[i].itemStandardSid &&
                data.itemManageNo == this.parentData[i].managementNo
              );
            });
            // 存在する場合、エラー表示
            if (findErrorData) {
              const lotObj = commonUtil.convertLotNo(findErrorData.lotNo);
              this.dataItems.push({
                itemCd: findErrorData.itemCd,
                dueDate: lotObj.original.dueDate,
                quantity: findErrorData.stock,
              });
            }
          }
        }
      }
    },
    // 閉じる
    close() {
      this.$emit("update:isShow", false);
      this.dataItems = [];
    },
  },
  computed: {},
  watch: {
    // 表示フラグ確認
    isShow(newVal) {
      // 表示フラグが変更した場合、初期化
      if (newVal) {
        this.init();
      }
    },
  },
  mounted() {
    // 初期化
    this.init();
  },
};
</script>
<style>
@import "../css/style.css";
</style>
<style lang="scss" scoped>
#dialog-title {
  font-size: x-large;
  font-weight: bold;
  padding: 0 0 0 0.3em;
}

#dialog-content {
  padding: 0;
  color: black;
  background: #dee6f3;
}
</style>
